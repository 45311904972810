/* eslint-disable prettier/prettier */
'use client';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import styles from './styles/modal.module.scss';
import { v1_arrow_back, v1_close } from '@/services/utils/svg';

interface Props {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    clsModalContent?: string;
    clsModalChild?: string;
    children: React.ReactNode;
    customStyle?: boolean;
    title?: string | any;
    titleClose?: boolean;
    disabledClose?: boolean;
    center?: boolean;
    minHeight?: string;
    maxWdt?: string;
    transparent?: boolean;
    footer?: React.ReactNode;
    bottomModal?: boolean;
    iconBack?: boolean;
    absolute?: boolean;
    maxHgt?: any;
    contentDesign?: any;
    subtitle?: any;
    style?: any;
}

const Modal: React.FC<Props> = ({
    isOpen,
    setIsOpen,
    clsModalContent,
    clsModalChild,
    minHeight,
    maxWdt,
    maxHgt,
    children,
    customStyle,
    title,
    titleClose,
    disabledClose,
    center,
    transparent,
    footer,
    bottomModal,
    iconBack,
    absolute,
    contentDesign,
    subtitle,
    style
}) => {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);

    if (typeof window === 'undefined') {
        return <></>;
    }

    return createPortal(
        <>
            {isOpen && (
                <div
                    className={`${styles.modal} ${center && styles.center_modal} ${customStyle && styles.custom_style} ${bottomModal ? styles.bottom_modal : ''
                        } ${absolute ? styles.modal_absolute : ''}`}
                    style={{ ...style }}
                >
                    <div
                        className={styles.overlay}
                        onClick={(e: any) => {
                            e.preventDefault();
                            !disabledClose && setIsOpen(false);
                        }}
                    >
                        &nbsp;
                    </div>
                    {!titleClose && !disabledClose && maxHgt && (
                        <div className={styles.close_top}>
                            <span
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    setIsOpen(false);
                                }}
                            >
                                &times;
                            </span>
                        </div>
                    )}
                    <div
                        className={`${styles.modalContent} ${clsModalContent} ${transparent ? styles.transparent : ''}`}
                        style={{ minHeight: minHeight ? minHeight : '', maxWidth: maxWdt ? maxWdt : '', maxHeight: maxHgt ? maxHgt : '', ...contentDesign }}
                    >
                        {!titleClose && !disabledClose && !maxHgt && (
                            <div className={styles.close}>
                                <span
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setIsOpen(false);
                                    }}
                                >
                                    &times;
                                </span>
                            </div>
                        )}
                        {title && (
                            <h3 className={`${styles.title} ${subtitle ? styles.with_subtitle : ''}`}>
                                <div className={styles.title_with_close}>
                                    {title}
                                    {titleClose && !disabledClose && (
                                        <span
                                            onClick={(e: any) => {
                                                e.preventDefault();
                                                setIsOpen(false);
                                            }}
                                        >
                                            {iconBack ? v1_arrow_back : v1_close}
                                        </span>
                                    )}
                                </div>
                                {subtitle && <span>{subtitle}</span>}
                            </h3>
                        )}

                        <div className={`${clsModalChild} ${styles.child}`}>{children}</div>
                        {footer}
                    </div>
                </div>
            )}
        </>,
        document.body as HTMLElement
    );
};

export default Modal;
