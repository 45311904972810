'use client';
import ShowAlert from '@/components/atoms/alert-toast';
import { usePathname, useSearchParams } from 'next/navigation';
import { Suspense, createContext, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import usePixalEvent from '../event-log-tracking/usePixalEvent';

const AlertContext = createContext<any>(undefined);

const UpdateSearch = () => {
    const search: any = useSearchParams();
    const pathname = usePathname();
    const { page_view } = usePixalEvent();

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        const url = `${pathname}${searchParams.toString() ? '?' : ''}${searchParams.toString()}`;
        page_view(url);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, search]);

    return null;
};

export const AlertProvider = ({ children }: any) => {
    const [alert, setAlert] = useState<any>(null);

    const showAlert = ({ message, cancel, okeyBtnText, cancelBtnText, subtitle, src, icon, simple }: any) => {
        return new Promise((resolve) => {
            setAlert({
                message,
                show: true,
                cancel,
                resolve,
                okeyBtnText: okeyBtnText,
                cancelBtnText: cancelBtnText,
                src,
                icon,
                subtitle,
                simple
            });
        });
    };

    const hideAlert = () => {
        setAlert(null);
    };

    useEffect(() => {
        const getToastMain = document?.querySelector('.commonParent');

        if (getToastMain) {
            const timerId = setInterval(() => {
                toast.dismiss();
            }, 3000);

            // Clean up the interval when the component unmounts
            return () => {
                clearInterval(timerId);
            };
        }
    }, []);

    if (typeof window !== 'undefined') {
        const hasSeenWarning = sessionStorage.getItem('hasSeenWarning');

        if (!hasSeenWarning) {
            console.log('%cStop!', 'color: red; font-size: 50px; font-weight: bold;');
            console.log(
                '%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable arogga.com features or "hack" someone\'s account, it is a scam and will give them access to your arogga.com account.',
                'color: red; font-size: 20px;'
            );

            sessionStorage.setItem('hasSeenWarning', 'true');
        }
    }
    return (
        <AlertContext.Provider value={{ showAlert, setAlert }}>
            <Suspense fallback={null}>{typeof window === 'undefined' ? '' : <UpdateSearch />}</Suspense>
            {children}
            {alert?.show && (
                <ShowAlert
                    icon={alert?.icon}
                    simple={alert?.simple}
                    src={alert?.src}
                    message={alert?.message}
                    subtitle={alert.subtitle}
                    cancel={alert?.cancel}
                    okeyBtnText={alert?.okeyBtnText}
                    cancelBtnText={alert?.cancelBtnText}
                    onConfirm={(e) => {
                        alert.resolve(e);
                        hideAlert();
                    }}
                />
            )}
        </AlertContext.Provider>
    );
};

export const useAlert = () => {
    return useContext(AlertContext);
};
