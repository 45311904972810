'use client';
import styles from './styles/alert-toast.module.scss';
import Modal from '../molecules/modal';
import alertImg from '@/services/utils/icons/alert-icon.png';
import Img from '../molecules/img';

interface AlertProps {
    message?: string;
    subtitle?: any;
    title?: string;
    titleClose?: boolean;
    disabledClose?: boolean;
    content?: any;
    cancel?: boolean;
    okeyBtnText?: string;
    cancelBtnText?: string;
    onConfirm: (confirmed: boolean) => void;
    src?: any;
    icon?: any;
    simple?: boolean;
}

const ShowAlert = ({
    message,
    subtitle,
    title,
    titleClose = true,
    content,
    disabledClose = true,
    cancel,
    okeyBtnText,
    cancelBtnText,
    src,
    icon,
    onConfirm,
    simple
}: AlertProps) => {
    return (
        <Modal
            isOpen={true}
            // eslint-disable-next-line prettier/prettier
            setIsOpen={() => { }}
            center
            title={title}
            titleClose={titleClose}
            disabledClose={disabledClose}
            customStyle
            clsModalContent={`${styles.content_design_modal} ${simple ? styles.simple_content_design_modal : ''}`}
            minHeight={'0'}
            clsModalChild={content ? '' : styles.content_child_design}
            style={simple ? { zIndex: 99999 } : {}}
        >
            {content ? (
                content
            ) : (
                <div className={`${styles.default_style} ${simple ? styles.simple_style : ''}`}>
                    <div className={styles.img_wrap}>{icon ? icon : <Img src={src ? src : alertImg} />}</div>
                    <div className={styles.content_wrp}>
                        <p>{message}</p>
                        {subtitle && <span>{subtitle}</span>}
                        <div className={styles.button_list}>
                            {cancel && (
                                <button
                                    className={styles.cancel}
                                    onClick={() => {
                                        onConfirm(false);
                                    }}
                                >
                                    {cancelBtnText ? cancelBtnText : 'Cancel'}
                                </button>
                            )}
                            {cancel && <>&nbsp;&nbsp;&nbsp;</>}
                            <button
                                className={styles.ok}
                                onClick={() => {
                                    onConfirm(true);
                                }}
                            >
                                {okeyBtnText ? okeyBtnText : 'Okay'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default ShowAlert;
